<template>
    <div class="screening-question-card">
        <div class="d-flex justify-content-between align-items-center">
            <h3 class="screening-question-card__title">{{ $t("Question")}}: {{ (index + 1) }}</h3>
            <div class="d-flex ml-auto justify-content-end my-2">
                <a href="javascript:void(0)" class="control-button control-button--info has_bg_color" v-if="question.isMultiple" @click.prevent="addOption(question)">
                    <div class="control-button__icon">
                        <i class="eicon e-plus"></i>
                    </div>
                    <span>{{$t('Add Option')}}</span>
                </a>
                <a href="javascript:void(0)" class="control-button control-button--primary has_bg_color" @click="$emit('moveUp', index)" v-if="index > 0">
                    <div class="control-button__icon">
                        <i class="eicon e-arrow-up"></i>
                    </div>
                    <span>{{$t('Move Up')}}</span>
                </a>
                <a href="javascript:void(0)" class="control-button control-button--primary has_bg_color" @click="$emit('moveDown', index)" v-if="isLastQuestion">
                    <div class="control-button__icon">
                        <i class="eicon e-arrow-down"></i>
                    </div>
                    <span>{{$t('Move Down')}}</span>
                </a>
                <a href="javascript:void(0)" @click="$emit('deleteQuestion', index)" class="erase-button"><i class="eicon e-delete"></i><span>{{$t('Delete')}}</span></a>
            </div>
        </div>
        <div class="form-box">
            <div class="row">
                <div class="col-md-4">
                    <div class="form-group mb-0">
                        <label :for="`question_type-${index}`">{{ $t("Question Type") }}*</label>
                        <multiselect
                            :id="`question_type-${index}`"
                            v-model="question.type"
                            :options="questionTypeOptions"
                            :placeholder="$t('Select Type')"
                            track-by="id"
                            label="name"
                            :allow-empty="false"
                            deselect-label=""
                            @input="changeQuestionType(question)"
                        ></multiselect>
                        <error-message :message="$t(errors[`questions.${index}.type`])"/>
                    </div>
                </div>
                <div class="col-md-8">
                    <div class="form-group" :class="{'mb-0' : !question.isMultiple}">
                        <label :for="`question-${index}`">{{ $t("Type Your Question") }}*</label>
                        <input type="text" maxlength="250" name="" v-model.trim="question.title" :id="`question-${index}`" class="form-control" :placeholder="$t('Screening Question')"/>
                        <error-message :message="$t(errors[`questions.${index}.title`])"/>
                        <error-message :message="$t(question.errors['title'])"/>

                    </div>
                </div>
            </div>
            <template v-if="question.isMultiple">
                <div class="row">
                    <div class="col-md-3" v-for="(option, key) in question.options" :key="key">
                        <div class="form-group screening-question-answer">
                            <label>{{ $t("Option") }} {{ (key + 1) }}</label>
                            <div class="d-flex align-items-center">
                                <label class="checkbox">
                                    <input type="checkbox" :value="key" v-model="question.answers"/>
                                    <span></span>
                                </label>
                                <input type="text" v-model.trim="option.title" class="form-control" :placeholder="$t('Option') + ` ${(key + 1)}`"/>
                                <a href="javascript:void(0)" class="remove-button" @click.prevent="removeOption(question, key)">
                                    <i class="eicon e-close"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                    <error-message :message="$t(errors[`questions.${index}.options`])"/>
                </div>

                <div class="form-note mt-2">
                    <span class="text-capitalize">{{ $t("note") }}: </span>
                    {{ $t("The checkboxes are for the correct answer, you can select one or more correct answer.") }}
                </div>
            </template>
            <div class="error-message mt-2" v-if="question.errors.length">
                {{ question.errors.join(' ') }}
            </div>
        </div>
    </div>
</template>

<script>

import Multiselect from "vue-multiselect";
import {JOB} from "../../extra/constants";
const ErrorMessage = () => import("../../components/common/ErrorMessage.vue");

export default {
    name: "ScreeningQuestion",

    props: [
        'question',
        'errors',
        'totalQuestion',
        'questionTypeOptions'
    ],

    components: {
        ErrorMessage,
        Multiselect
    },

    computed: {
        isLastQuestion() {
            return this.totalQuestion - 1 > this.index;
        },
        index() {
            return this.$vnode.key;
        }
    },

    methods: {
        addOption(question) {
            question.options.push({
                id: null,
                title: '',
            });
        },

        removeOption(question, index) {
            let ansIndex = question.answers.indexOf(index);
            if (ansIndex !== -1) {
                question.answers.splice(ansIndex);
            }

            question.options.splice(index, 1);
        },

        changeQuestionType(question) {
            question.errors = [];
            question.isMultiple = (question.type.id === JOB.QUESTION_TYPES.MULTIPLE_CHOICE);
            if(question.isMultiple && question.options.length === 0){
                this.addOption(question);
            }
        },
    }

}
</script>

<style scoped>

</style>
