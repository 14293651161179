<template>
    <form action="" @submit.prevent="">
        <div class="d-flex justify-content-between align-items-center">
            <h1>{{ $t("Screening Questions") }}</h1>
            <div>
                <a class="info-button btn btn-sm " v-if="warningMessage.length>0 && !canEdit" href="javascript:void(0);" @click="allowToEdit()">
                    <i class="eicon e-pencil"></i> <span>{{ $t(`Edit`) }}</span>
                </a>
                <a class="danger-button btn btn-sm" v-if="canRemove" href="javascript:void(0);" @click="deleteAllQuestions()">
                    <i class="eicon e-delete"></i> <span>{{ $t(`Remove`) }}</span>
                </a>
            </div>
        </div>
        <div class="form-note">
            <span class="text-capitalize">{{ $t("note") }}: </span>
            {{ $t("If you want to ask any additional questions, please write here. It will appear in the application process.") }}
        </div>
        <template>
            <div class="alert fade show alert-warning text-center" role="alert" v-if="warningMessage.length>0">
                {{ warningMessage }}
            </div>
        </template>
        <template v-if="canEdit">
            <div class="section-title mt-5 mb-5  text-capitalize">{{ $t("Select an existing question set") }}</div>
            <div class="row">
                <div class="col-md-6 mt-4 mb-4">
                    <multiselect
                            id="question_set"
                            v-model="question_set"
                            :options="questionSetOptions"
                            :placeholder="$t('select a set.')"
                            track-by="id"
                            label="name"
                            @input="selectQuestionSet"
                    ></multiselect>
                </div>
            </div>
        </template>

        <screening-question
            v-for="(question, index) in form.questions"
            :key="index"
            :question="question"
            :errors="errors"
            :questionTypeOptions="questionTypeOptions"
            :total-question="form.questions.length"
            @moveUp="moveUp($event)"
            @moveDown="moveDown($event)"
            @deleteQuestion="deleteQuestion($event)"
        >
        </screening-question>

        <div class="section-gap">
            <button class="button primary-button text-capitalize" @click.prevent="addQuestion" v-if="canEdit">
                <span class="icon"><i class="eicon e-plus"></i></span>
                <span class="text-capitalize">{{ $t("add question") }}</span>
            </button>
        </div>
        <div class="section-gap d-flex justify-content-between" v-if="!isLoading">
            <router-link :to="{name: 'job.edit', currentJobId}" class="button semi-button-info text-capitalize mr-4">
                {{ $t("back") }}
            </router-link>
            <div class="d-flex ml-auto">
                <button class="button semi-button-danger mr-4" @click="cancelEdit()" v-if="canEdit">{{ $t("Cancel") }}</button>
                <router-link :to="{name: 'job.quiz', params: {id: currentJobId}}" class="button semi-button-warning text-capitalize mr-4" v-if="!canEdit">{{ $t("Skip & Next") }}</router-link>
                <div class="btn-group dropup" v-on-click-away="hideDropdown">
                    <submit-button :click="saveScreeningQuestion" :loading="isLoading" :disabled="isEmpty || !canEdit" v-if="quiz">{{ $t("Save and Continue") }}</submit-button>
                    <submit-button :click="saveOnly" :loading="isLoading" :disabled="isEmpty || !canEdit" v-else>{{ $t("Save") }}</submit-button>
                    <button type="button" class="button info-button dropdown-toggle" :class="{'disabled': isEmpty || !canEdit }" data-bs-toggle="dropdown" aria-expanded="false" :disabled="isEmpty || !canEdit" @click="showDropdown= !showDropdown">
                        <span class="visually-hidden"></span>
                    </button>
                    <div class="dropdown-menu show" v-if="showDropdown">
                        <a href="javascript:void(0);" style="font-size: 10px;" @click.prevent="saveScreeningQuestion(false)" v-if="quiz">{{ $t("Save only") }}</a>
                        <a href="javascript:void(0);" style="font-size: 10px;" @click.prevent="saveScreeningQuestion()" v-else>{{ $t("Save and Continue") }}</a>
                    </div>
                </div>
            </div>
        </div>
    </form>
</template>
<script>
import Multiselect from 'vue-multiselect';
import client from '../../app/api/Client';
import {getJobScreeningData, getJobScreeningMetaData, getQuestionSet} from "../../app/api/CommonRequest";
import {JOB} from '../../extra/constants';
import {EventBus} from "../../extra/event-bus";
import {CHECK_AND_PUBLISH_JOB} from "../../constants/events";
import {mapState} from "vuex";
import {isEmpty as _isEmpty} from "lodash";
import {canPublishJob} from "../../config/permission";
const ErrorMessage = () => import("../../components/common/ErrorMessage");
import evalution from "../../services/validations/evalution"
import ScreeningQuestion from "../../components/questions/ScreeningQuestion";

export default {
    props: [
        'job'
    ],
    components: {
        ErrorMessage,
        ScreeningQuestion,
        Multiselect
    },
    data() {
        return {
            currentJob: {},
            editMode: false,
            isPublished: false,
            form: {
                job_id: null,
                questions: []
            },
            question_set: null,
            questionSetOptions: [],
            questionTypeOptions: [],
            hasErrorInQuestions: false,
            errors: [],
            isLoading: true,
            warningMessage: '',
            showDropdown: false,
            canRemove: false
        }
    },
    computed: {
        ...mapState(['company']),
        currentJobId() {
            return this.$route.params.id;
        },
        isEmpty() {
            return !this.form.questions.length;
        },
        hasApplicant() {
            return this.currentJob.applicant_count > 0;
        },
        canEdit() {
            return this.editMode;
        },
        newScreeningQuestion() {
            return _isEmpty(this.currentJob.screening_group);
        },
        quiz() {
            return !this.isPublished;
        },
        canPublishJob() {
            return canPublishJob();
        }
    },
    methods: {
        async saveOnly() {
            await this.saveScreeningQuestion(false)
        },
        hideDropdown() {
            this.showDropdown = false;
        },
        async cancelEdit() {
            this.form.job_id = this.$route.params.id;
            await this.findJobScreeningMetaData();
            await this.findJobScreeningData();
        },

        allowToEdit() {
            let message = {
                title: this.$t("Confirmation"),
                body: `<div class="custom-delete">
                                <p class="text-center">${this.$t(' Only new applicants will get updated questions & marks will be on new question set. Old applicant\'s marks will not be changed.')}</p>
                                <br>
                                <p class="text-center">${this.$t('Are you sure you want to edit questions?')}</p>
                         </div>`
            };

            this.$dialog.confirm(message).then(() => {
                this.editMode = true;
            });
        },

        async deleteAllQuestions() {
            let message = {
                title: this.$t("Confirmation"),
                body: `<div class="custom-delete">
                                <p class="text-center">${this.$t(' Only new applicants will not be able to answer questions. Old applicant\'s marks will not be changed.')}</p>
                                <br>
                                <p class="text-center">${this.$t('Are you sure you want to delete questions?')}</p>
                         </div>`
            };

            await this.$dialog.confirm(message).then(() => {
                client().delete(`/job/${this.currentJobId}/screening`).then(({data}) => {
                    this.$toast.success(this.$t(data.message));
                    this.canRemove = false;
                } ).catch(({data})=> {
                    this.$toast.error(this.$t(data.message));
                }).finally(()=>{
                    this.form.questions = [];
                    this.findJobScreeningMetaData();
                    this.findJobScreeningData();
                });
            });
        },

        moveUp(index, elem) {
            [this.form.questions[index], this.form.questions[index - 1]] = [this.form.questions[index - 1], this.form.questions[index]];
            this.form.questions = this.form.questions.map(e => e);
        },

        moveDown(index, $event) {
            [this.form.questions[index], this.form.questions[index + 1]] = [this.form.questions[index + 1], this.form.questions[index]];
            this.form.questions = this.form.questions.map(e => e);
        },

        async saveScreeningQuestion(next = true) {
            if (!this.form.job_id) {
                this.$toast.error(this.$t('Invalid job id.'));
                return 0;
            }

            this.validateQuestions();

            if (this.hasErrorInQuestions) {
                this.$toast.error(this.$t('Oops! Please check your input'));
                return false;
            }

            this.isLoading = true;
            try {
                let {data} = await client().post(`/job/${this.currentJobId}/screening`, this.form);
                if (data.status === 'SUCCESS') {
                    this.$toast.success(this.$t(data.message));
                    this.canRemove = true;
                    if (next) {
                        if (this.company.package_rule.quiz) {
                            await this.$router.push({name: 'job.quiz', params: {id: data.data.slug}});
                        } else {
                            await this.$router.push({name: 'job.edit', params: {id: data.data.slug}});
                            if(this.canPublishJob) {
                                EventBus.$emit(CHECK_AND_PUBLISH_JOB);
                            }
                        }
                    }
                } else {
                    this.$toast.error(this.$t(data.message));
                }
            } catch (err) {
                if (err.response.status === 422) {
                    this.errors = _helper.serializeValidationMessage(err.response.data.message);
                    this.$toast.error(this.$t('Oops! Please check your input'));
                } else {
                    this.$toast.error(this.$t(err.response.data.message));
                }
            }
            this.editMode = false;
            this.isLoading = false;
        },

        addQuestion() {
            this.validateQuestions();
            if (this.hasErrorInQuestions) {
                return false;
            }

            this.form.questions.push({
                id: null,
                type: '',
                title: "",
                options: [
                    {
                        id: null,
                        title: '',
                    }
                ],
                answers: [],
                isValid: false,
                errors: [],
                isMultiple: false
            });
        },

        deleteQuestion(key) {
            let message = {
                title: this.$t('Confirmation'),
                body: this.$t('do you really want to delete this question?')
            };

            this.$dialog.confirm(message).then(() => {
                this.form.questions.splice(key, 1);
            });
        },

        selectQuestionSet() {
            if (!this.question_set) {
                this.$toast.error(this.$t('select a set.'));
            }

            getQuestionSet(this.question_set.id).then((data) => {
                this.form.questions = data.questions;
            });
        },

        checkEditMode() {
            if (this.currentJob.applicant_count > 0) {
                this.warningMessage = this.currentJob.applicant_count + ' ' + this.$t(`applicant(s) have already applied for this job. If you edit question, old applicant's marks will not be changed. Only new applicants will get new questions.`);
                this.editMode = false;
            } else {
                this.editMode = true;
            }
        },

        validateQuestions() {
            let {hasErrorInQuestions} = evalution.validateQuestions(this.form.questions);
            this.hasErrorInQuestions = hasErrorInQuestions;
        },

        findJobScreeningMetaData() {
            getJobScreeningMetaData(this.currentJobId).then((data) => {
                this.questionSetOptions = data.question_set;
                this.questionTypeOptions = data.question_types;
            });
        },

        async findJobScreeningData() {
            this.question_set = null;
            await getJobScreeningData(this.currentJobId).then((data) => {
                if (data.status === 'SUCCESS') {
                    this.currentJob = data.data;
                    this.form.job_id = this.currentJob.id;
                    this.form.questions = [];
                    if (this.currentJob.questions.length) {
                        this.form.questions = this.currentJob.questions;
                        this.canRemove = true;

                    }
                    this.isPublished = this.currentJob.status === JOB.STATUS.PUBLISHED;
                } else {
                    this.$toast.error(this.$t(data.message));
                }
            }).finally(() => {
                this.checkEditMode();
            });
        },



    },
    async created() {
        this.form.job_id = this.$route.params.id;
        await this.findJobScreeningMetaData();
        await this.findJobScreeningData();
        this.isLoading = false;
    }
}
</script>

<style scoped>
.btn-group button:first-of-type {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}

.btn-group button:last-of-type {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    padding: 2px 20px 2px 0px;
}

.btn-group button.info-button:last-of-type {
    border-left: 1px solid #46c8e3;
}

.btn-group.dropup button:last-of-type:after {
    transform: rotate(180deg);
}

.btn-group.dropup {
    position: relative;
}

.btn-group.dropup .dropdown-menu {
    position: absolute;
    right: 0px;
    padding: 5px;
    box-shadow: 0 5px 10px rgba(125, 128, 145, 0.3);
    border: none;
}

.btn-group.dropup .dropdown-menu a {
    display: flex;
    padding: 5px 10px;
    font-size: 0.9375rem;
}

.btn-group.dropup .dropdown-menu a:hover {
    background: #f5f7fd;
}


.custom-check-box {
    color: rgb(125, 128, 145);
    font-size: .85rem;
    font-weight: 300;
}
</style>
