import {findIndex, remove, uniqBy} from "lodash";

export function pushErrorMessage(array, message) {
    if (array.indexOf(message) < 0) {
        array.push(message);
    }
}

export function removeErrorMessage(array, message) {
    remove(array, (msg) => {
        return msg == message;
    })
}

export function checkDuplicateQuestion(questions, question, key) {
    let newQuestions = questions.slice(0, key + 1);

    let index = findIndex(newQuestions, (que) => {
        return que.title.trim().toLowerCase() === question.title.trim().toLowerCase();
    });

    return index < key;
}

export function checkQuestionMultipleSameAnswer(options) {
    let uniqueOptions = uniqBy(options,(option) => {
        return option.title.trim().toLowerCase();
    });

    return options.length !== uniqueOptions.length;
}
