import {findIndex, forEach, isEmpty, trim} from "lodash";
import {
    checkDuplicateQuestion,
    checkQuestionMultipleSameAnswer,
    pushErrorMessage,
    removeErrorMessage
} from "../../extra/validations";
import {JOB} from "../../extra/constants";
import helper from "../../extra/helper"

export default {

    validateAssessment(formData) {
        let errors = {};
        let hasErrorInQuestions = false;

        if (formData.assessment_name.length === 0 || formData.assessment_name.length > 100) {
            errors.assessment_name = 'Please enter assessment name within 100 characters.';
        }

        if (formData.exam_duration.length === 0) {
            errors.exam_duration = `Assessment duration is required.`;
        } else if (helper.notValidInteger(trim(formData.exam_duration))) {
            errors.exam_duration = `Provide a valid integer assessment duration.`;
        } else if (formData.exam_duration.length > 5) {
            errors.exam_duration = `Please input quiz duration within 5 digits.`;
        }

        if (formData.marks_per_question.length === 0) {
            errors.marks_per_question = `Please input marks per question.`;
        } else if (helper.notValidInteger(trim(formData.marks_per_question))) {
            errors.marks_per_question = `Please provide a valid integer marks per question.`;
        } else if (formData.marks_per_question.length > 5) {
            errors.marks_per_question = `Please input marks per question within 5 digits.`;
        }

        if (formData.set_type.length === 0) {
            errors.set_type = `Set Type cannot be empty.`;
        }

        if (!isEmpty(errors)) {
            hasErrorInQuestions = true;
        }

        return {errors, hasErrorInQuestions}
    },

    validateQuestionSet(formData) {
        let errors = {};
        let hasErrorInQuestions = false;


        if (formData.set_name.length === 0 || formData.set_name.length > 100) {
            errors.set_name = 'Please enter set name within 250 characters.';
        }

        if (formData.set_type.length === 0) {
            errors.set_type = `Set Type cannot be empty.`;
        }

        if (!isEmpty(errors)) {
            hasErrorInQuestions = true;
        }

        return {errors, hasErrorInQuestions}
    },

    validateJobQuiz(formData) {
        let errors = {};
        let hasErrorInQuestions = false;

        if (formData.exam_duration.length === 0) {
            errors.exam_duration = `Please input quiz duration.`;
        } else if (helper.notValidInteger(trim(formData.exam_duration))) {
            errors.exam_duration = `Please provide a valid integer quiz duration.`;
        } else if (formData.exam_duration.length > 5) {
            errors.exam_duration = `Please input quiz duration within 5 digits.`;
        }

        if (formData.marks_per_question.length === 0) {
            errors.marks_per_question = `Please input marks per question.`;
        } else if (helper.notValidInteger(trim(formData.marks_per_question))) {
            errors.marks_per_question = `Please provide a valid integer marks per question.`;
        } else if (formData.marks_per_question.length > 5) {
            errors.marks_per_question = `Please input marks per question within 5 digits.`;
        }

        if (!isEmpty(errors)) {
            hasErrorInQuestions = true;
        }

        return {errors, hasErrorInQuestions}
    },

    validateQuestions(questions, isQuiz = false) {
        this.hasErrorInQuestions = false;

        forEach(questions, (question, key) => {
            question.errors = [];

            if (checkDuplicateQuestion(questions, question, key)) {
                let errorKey = findIndex(questions, (que) => {
                    return que.title === question.title;
                });
                questions[key].errors['title'] = JOB.ERRORS.INVALID_QUESTION_ALREADY_EXIT + (errorKey + 1) + '.';
                this.hasErrorInQuestions = true;
            }

            if (isQuiz) {
                this.validateQuizQuestion(key, question);
            } else {
                this.validateScreeningQuestion(key, question);
            }
        });


        return {hasErrorInQuestions: this.hasErrorInQuestions};
    },

    validateQuizQuestion(key, question) {
        question.title = trim(question.title);
        if (question.title.length < 1 || question.title.length > 250) {
            question.errors['title'] = JOB.ERRORS.INVALID_QUESTION_TITLE;
            this.hasErrorInQuestions = true;
        }

        this.questionOptionsValidate(question);
    },

    validateScreeningQuestion(key, question) {
        if (question.type.length === 0) {
            pushErrorMessage(question.errors, JOB.ERRORS.INVALID_QUESTION_TYPE);
            this.hasErrorInQuestions = true;
            return false;
        } else {
            removeErrorMessage(question.errors, JOB.ERRORS.INVALID_QUESTION_TYPE);
        }

        question.title = trim(question.title);
        if (question.title.length < 1 || question.title.length > 250) {
            question.errors['title'] = JOB.ERRORS.INVALID_QUESTION_TITLE;
            this.hasErrorInQuestions = true;
        }

        if (question.type.id === JOB.QUESTION_TYPES.MULTIPLE_CHOICE) {
            this.questionOptionsValidate(question);
        }
    },

    questionOptionsValidate(question) {

        if (question.options.length > 0) {
            removeErrorMessage(question.errors, JOB.ERRORS.EMPTY_QUESTION_OPTIONS);
        } else {
            pushErrorMessage(question.errors, JOB.ERRORS.EMPTY_QUESTION_OPTIONS);
            this.hasErrorInQuestions = true;
            return false;
        }

        for (let iox = 0, size = question.options.length; iox < size; iox++) {
            let optionX = question.options[iox];
            if (optionX.title.length === 0) {
                pushErrorMessage(question.errors, JOB.ERRORS.INVALID_QUESTION_OPTION);
                this.hasErrorInQuestions = true;
                return false;
            } else {
                removeErrorMessage(question.errors, JOB.ERRORS.INVALID_QUESTION_OPTION);
            }
        }

        if(checkQuestionMultipleSameAnswer(question.options)) {
            pushErrorMessage(question.errors, JOB.ERRORS.INVALID_MULTIPLE_SAME_ANSWER);
            this.hasErrorInQuestions = true;
            return false;
        } else {
            removeErrorMessage(question.errors, JOB.ERRORS.INVALID_MULTIPLE_SAME_ANSWER);
        }

        if (question.answers.length === 0) {
            pushErrorMessage(question.errors, JOB.ERRORS.INVALID_OPTION_ANSWER);
            this.hasErrorInQuestions = true;
            return false;
        } else {
            removeErrorMessage(question.errors, JOB.ERRORS.INVALID_OPTION_ANSWER);
        }
    }
}
